<template>
    <div class="corporate-options-wrapper">
        <div class="top">{{$t('login.createAddEnterpriseInformation')}}</div>
        <div class="body">
            <div class="options" v-if="(invitationList && invitationList.length > 0) || (merchants && merchants.length > 0)">
                <div class="incentive"  v-for="(item,index) in invitationList" :key="index">
                    <div class="title">{{item.info.name}}</div>
                    <div class="subtitle">{{$t('login.theEnterprise', {key: item.invitation.createTime})}}</div>
                    <div class="operation">
                        <el-button  @click="agreeMerchant(item)" size="mini" type="primary">接受邀请</el-button>
                        <el-button  @click="disagreeMerchant(item.invitation.invitationId)" size="mini">忽略</el-button>
                    </div>
                </div>
                <div class="entrance" @click="setMerchant(item)"  v-for="(item,index) in merchants" :key="index">
                    <div class="title">{{item.merchantName}}</div>
                    <div class="operation"><i-right :strokeWidth="3"/></div>
                    <div class="subtitle">{{$t('login.enterImmediately',{key: item.lastEntryTime})}}</div>
                </div>
            </div>
            <CustomCard
                    :title="$t('login.startYourBusinessOrganization')"
                    :subtitle="$t('login.improveEnterpriseInformationOrganization')"
                    :url="defaultText.url"
                    @click="()=> this.$router.push('/createEnterpriseInformation')"
            />
        </div>
    </div>
</template>

<script>
    import CustomCard from "../../../components/info/CustomCard";
    import {
        ConsentToEnterMerchant,
        DisagreeToJoinMerchant,
        getInvitationList,
        getMerchantAccountCards, updateLastEntryTime
    } from "../../../request/api/merchant";

    export default {
        name: "EnterpriseInformation",
        components: {CustomCard},
        data() {
            return {
                invitationList: [],
                merchants: [],
                defaultText: {
                    url: require('../../../assets/edit-imgs/login/78461532.png')
                }
            }
        },
        mounted() {
            this.getMerchantAccountCards();
            this.getInvitationList();
        },
        methods: {
            setMerchant(item) {
                let _this = this;
                _this.updateLastEntryTime(item.merchantId, function () {
                    console.log(item);
                    _this.$MERCHANT(item);
                    _this.$router.replace("/manager/dashboard");
                })
            },
            getInvitationList() {
                let _this = this;
                getInvitationList().then(result => {
                    if (result.code === 200) {
                        console.log(result);
                        _this.invitationList = result.data;
                    } else {
                        console.error(result)
                    }
                })
            },
            agreeMerchant(item) {
                console.log(item)
                let _this = this;
                ConsentToEnterMerchant({
                    invitationId: item.invitation.invitationId
                }).then(result => {
                    this.$MESSAGE(result, function () {
                        _this.setMerchant({
                            merchantId: item.info.id,
                            merchantName: item.info.name,
                        })
                    })
                })
            },
            disagreeMerchant(invitationId) {
                let _this = this;
                DisagreeToJoinMerchant({
                    invitationId: invitationId
                }).then(result => {
                    this.$MESSAGE(result, function () {
                        _this.getInvitationList();
                    })
                })
            },
            getMerchantAccountCards() {
                let _this = this;
                getMerchantAccountCards().then((result) => {
                    if (result.code === 200) {
                        _this.merchants = result.data;
                    } else {
                        console.log(result)
                    }
                })
            },
            updateLastEntryTime(merchantId, fallback) {
                updateLastEntryTime({
                    merchantId: merchantId
                }).then(result => {
                    if (result.code === 200) {
                        fallback();
                    } else {
                        console.error(result)
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index.less";


    .corporate-options-wrapper {
        width: 308px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .top {
            font-weight: bold;
            font-size: 18px;
        }
        .body {

            .options {
                border: solid 1px #EEEEEE;
                width: 100%;
                height: 331px;
                border-radius: 8px;
                margin-top: 24px;
                overflow-y: scroll;
                -webkit-box-shadow: 0 1px 12px 0 fade(#F6F6F6, 10%);
                box-shadow: 0 1px 12px 0 fade(#F6F6F6, 10%);

                .incentive {
                    height: 110px;
                    box-sizing: border-box;
                    padding: 12px 16px 12px 16px;
                    border-bottom: solid 1px #F6F6F6;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #333333;
                    }
                    .subtitle {
                        font-size: 12px;
                        color: #999999;
                        margin-top: 4px;
                    }
                    .operation {
                        margin-top: 13px;
                    }
                }
                .entrance {
                    height: 80px;
                    border-bottom: solid 1px #F6F6F6;
                    box-sizing: border-box;
                    padding: 12px 16px 12px 16px;
                    cursor: pointer;

                    .title {
                        width: 250px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #333333;
                        .single-line;
                    }

                    .operation {
                        color: #999999;
                        font-size: 18px;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        position: relative;
                    }

                    // .operation:hover {

                    // }

                    .subtitle {
                        font-size: 12px;
                        color: #999999;
                        margin-top: -10px;
                    }

                    &:hover {
                        .operation {
                            color: @color-primary !important;
                        }
                    }
                }
            }
        }
    }
</style>

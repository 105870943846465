<template>
    <div>
        <el-row>
            <el-col>
                <div class="custom-box-card">
                    <div class="custom-box-card-left" @click="click">
                        <div class="custom-box-card-left-title">
                            <span>{{title}}</span>
                        </div>
                        <div class="custom-box-card-left-subtitle">
                            <span>{{subtitle}}</span>
                        </div>
                        <div class="custom-box-card-left-slot">
                            <slot></slot>
                        </div>
                    </div>
                    <div  class="custom-box-card-right">
                        <el-image class="image-slot" v-if="url" :src="url">
                            <template slot="error">
                                <img  class="image-slot"
                                      src="https://youpinimg.oss-cn-beijing.aliyuncs.com/caozanpro/49856026.png"/>
                            </template>
                        </el-image>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "CustomCard",
        props: {
            subtitle: String,
            title: String,
            url: {
                type: String,
                default: null,
            }
        },
        methods: {
            click() {
                this.$emit("click")
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";
    .custom-box-card:hover{
        transition: 400ms;
        border: solid 1px @color-primary;
        -webkit-box-shadow: 0 1px 12px 0 fade(@color-primary, 10%);
        box-shadow: 0 1px 12px 0 fade(@color-primary, 10%);
    }
    .custom-box-card {
        border: solid 1px #EEEEEE;
        width: 308px;
        height: 85px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: @border-radius-lg;
        cursor: pointer;

        .custom-box-card-left {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-indent: 1em;
            border-radius: @border-radius-lg;

            .custom-box-card-left-title {
                width: 100%;
                height: 36%;
                line-height: 40px;
                color: @color-primary;
                font-weight: 600;
                font-size: 14px;

            }

            .custom-box-card-left-subtitle {
                width: 100%;
                height: 30%;
                color: #999999;
                font-size: 12px;
            }

            .custom-box-card-left-slot {
                width: 100%;
                flex: 1;
                color: #999999;
                font-size: 12px;
            }
        }

        .custom-box-card-right {
            height: 80%;
            flex: 1;
            border-radius: 20px;
            margin-right: 10px;

            .image-slot {
                width:100%;
                height: 100%;
                border-radius: @border-radius-lg;
            }
        }
    }
</style>
